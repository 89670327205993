<template>
  <div>
    <div class="d-flex flex-column" v-if="isOwnRequest">
      <h5 class="h5-text mb-8"> Запрос на поиск транспортного средства </h5>
      <div class="page-block flex-column mb-8">
        <div class="subhead-text"> Запрос #{{ leasObjectDetails.id }} от {{ leasObjectDetails.date }} </div>
        <leas-object-details
          :leas-object-details="leasObjectDetails"
        ></leas-object-details>
      </div>
      <div class="page-block flex-column mb-8">
        <div class="subhead-text mb-2">В салоне есть данный автомобиль и (или) аналогичный</div>
        <div class="caption-text mb-8">Вы можете добавить спецификацию автомобиля и указать актуальную цену автосалона</div>
        <div class="d-flex flex-column flex-md-row">
          <leas-object-response
            ref="leasObjectResponseForm"
            :dealerships="dealerships"
            :archived="leasObjectDetails.archived"
          ></leas-object-response>
          <div class="d-flex flex-column ml-0 ml-md-8 mt-8 mt-md-0">
            <template
                v-for="leasObjectResp in leasObjectResps"
                >
              <template v-if="leasObjectResp.rezerv != null">
                <template v-if="leasObjectResp.rezerv.rezerv_status_id === 2">
                  <v-tooltip
                      :key="'confirm' + leasObjectResp.id"
                      bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          x-large
                          color="disabled"
                          class="mb-5"
                          v-bind="attrs"
                          v-on="on"
                      >
                        Резерв подтвержден
                      </v-btn>
                    </template>
                    <span>Резерв подтвержден {{leasObjectResp.rezerv.ds_manager_update_at}}</span>
                  </v-tooltip>
                </template>
                <template v-else-if="leasObjectResp.rezerv.rezerv_status_id === 3">
                  <v-tooltip
                      :key="'confirm' + leasObjectResp.id"
                      bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          x-large
                          color="disabled"
                          class="mb-5"
                          v-bind="attrs"
                          v-on="on"
                      >
                        Нет резерва
                      </v-btn>
                    </template>
                    <span>Резерв отменен {{leasObjectResp.rezerv.ds_manager_update_at}}</span>
                  </v-tooltip>
                </template>
                <template v-else-if="leasObjectResp.rezerv.rezerv_status_id === 1">
                  <v-btn
                      :key="'confirm' + leasObjectResp.id"
                      data-disabled="leasObjectDetails.archived"
                      outlined
                      x-large
                      color="primary"
                      class="mb-5"
                      @click="openModalRezervConfirm(leasObjectResp.rezerv.id)"
                  >
                    Подтвердить резерв
                  </v-btn>
                  <v-btn
                      :key="'cancel' + leasObjectResp.id"
                      data-disabled="leasObjectDetails.archived"
                      outlined
                      x-large
                      color="error"
                      class="mb-5"
                      @click="openModalRezervCancel(leasObjectResp.rezerv.id)"
                  >
                    Нет резерва
                  </v-btn>
                </template>
              </template>
            </template>
            <v-btn
              data-disabled="leasObjectDetails.archived"
              outlined
              x-large
              color="error"
              class="mb-5"
              @click="sendLeasObjectResponse(true)"
            >
              Авто нет в наличии
            </v-btn>
  <!--          <v-btn-->
  <!--            outlined-->
  <!--            x-large-->
  <!--            color="success"-->
  <!--            class="mb-5"-->
  <!--          >-->
  <!--            <v-icon class="mr-2">$chat</v-icon>-->
  <!--            <span> Новое сообщение </span>-->
  <!--          </v-btn>-->
            <v-btn
              data-disabled="leasObjectDetails.archived"
              color="secondary"
              x-large
              depressed
              @click="sendLeasObjectResponse()"
            >
              <span> Отправить </span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="page-block flex-column"
           v-resize="checkTypeTable"
           >
        <div v-if="isTableList">
        <div class="subhead-text mb-2">Отправленные предложения</div>

        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <span class="caption-s-text">Дата отправки</span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <span class="caption-s-text">Файл спецификации</span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <span class="caption-s-text">Цена в автосалоне</span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <span class="caption-s-text">Комментарий</span>
          </v-col>
        </v-row>
        <v-row
          v-for="leasObjectResp in leasObjectResps"
          :key="leasObjectResp.id"

        >
          <v-col
            cols="12"
            md="3"
          >
            <span class="subhead-text"> {{ leasObjectResp.date }} </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="body-s-text-text"
          >
            <a
              v-if="leasObjectResp.comProposal.path"
              :href="cpLink(leasObjectResp.comProposal.path)"
              target="_blank"
            >
              <span> Скачать </span>
            </a>
            <span v-else> - </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <span class="body-l-text"> {{ convertPrice(leasObjectResp.price) }} </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <span class="body-l-text"> {{ leasObjectResp.comment }} </span>
          </v-col>
        </v-row>
        </div>

        <div
                v-else
                class="table__cards"
        >
          <div
                  v-for="leasObjectResp in leasObjectResps"
                  :key="leasObjectResp.id"
                  class="table-card mb-0"
          >
            <div class="table-card__item mb-8">
              <div class="caption-text mb-2">Дата отправки</div>
              <div class="body-l-text mb-4"> {{ leasObjectResp.date }} </div>

              <div class="caption-text mb-2">Файл спецификации</div>
              <div class="body-l-text mb-4"> <a
                      v-if="leasObjectResp.comProposal.path"
                      :href="cpLink(leasObjectResp.comProposal.path)"
                      target="_blank"
              >
                <span> Скачать </span>
              </a>
                <span v-else> - </span>
              </div>
              <div class="caption-text mb-2">Цена в автосалоне</div>
              <div class="body-l-text mb-4">
                <span class="body-l-text"> {{ convertPrice(leasObjectResp.price) }} </span>
              </div>
              <div class="caption-text mb-2">Комментарий</div>
              <div class="body-l-text mb-4">
                <span class="body-l-text"> {{ leasObjectResp.comment }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="noCarWarningDialog" max-width="350">
        <NoCarWarningDialog @close="noCarWarningDialog = false"></NoCarWarningDialog>
      </v-dialog>
      <v-dialog v-model="repeatRespWarningDialog" max-width="350">
        <RepeatRespWarningDialog @close="repeatRespWarningDialog = false" @submit="sendLeasObjectResponse(noCar)"></RepeatRespWarningDialog>
      </v-dialog>
      <rezerv-lc-modal
          :is-show-modal.sync="showModalRez"
          :id-offer-modal.sync="idOffer"
          :need-update-dealer.sync="needUpdate"
          :type-modal.sync="typeModal"
          :requestId="requestId"
      >
      </rezerv-lc-modal>
      <v-dialog
        max-width="500"
        v-model="noCarOfferDialog"
      >
        <NoCarOfferDialog
          @close="noCarOfferDialog = false"
          @submit="sendLeasObjectResponse(noCar)"
          @submitNoCarOffer="submitNoCarOffer"
        ></NoCarOfferDialog>
      </v-dialog>
    </div>
    <empty-state-card
      v-if="!isOwnRequest"
      title="Запрос с таким номером не найден"
      text=""
    ></empty-state-card>
  </div>
</template>

<script>
// import Component from 'vue-class-component'
// import moment, { Moment } from 'moment'
import LeasObjectDetails from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/LeasObjectDetails.vue'
import LeasObjectResponse from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/LeasObjectResponse.vue'
import RezervLcModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/RezervLсModal.vue'
import { Http } from '@/apis/Http'

import TableMixin from '@/mixins/TableMixin.vue'
import _ from 'lodash'
// import { Ref } from 'vue-property-decorator'
// import { VForm } from '@/mixins/FormMixin.vue'
import { stringUtils } from '@/utils/string'
import { system } from '@/utils/system'
// import { RezervStatusId } from '@/typings/AdminTyping'
import WS from '@/apis/WS'
import NoCarWarningDialog from './components/NoCarWarningDialog'
import RepeatRespWarningDialog from './components/RepeatRespWarningDialog'
import NoCarOfferDialog from './components/NoCarOfferDialog'
import EmptyStateCard from '@/views/components/EmptyStateCard.vue'

export default {
  // @Ref() readonly leasObjectResponseForm!: VForm

  mixins: [TableMixin],

  components: {
    LeasObjectDetails,
    LeasObjectResponse,
    NoCarWarningDialog,
    RepeatRespWarningDialog,
    RezervLcModal,
    NoCarOfferDialog,
    EmptyStateCard
  },
  // })

  data() {
    return {
      requestLeasObject: {
        id: 1,
        date: '2020-12-01 12:00:31',
        commercialProposalFile: '',
        price: 0,
        comment: '',
        archived: false
      },
      leasObjectDetails: {},
      dealerships: [],
      leasObjectResps: [],
      noCarWarningDialog: false,
      repeatRespWarningDialog: false,
      showModalRez: false,
      typeModal: 0,
      idOffer: 0,
      needUpdate: false,
      noCar: false,
      noCarOfferDialog: false,
      isOwnRequest: true,
      noCarOffer: null
    }
  },

  /* @Watch('needUpdate')
  onNeedUpdateChange(val) {
    if (val) {
      this.fetchLeasObjectResponseDetails()
      this.needUpdate = false
    }
  }, */

  watch: {
    needUpdate: function (val) {
      if (val) {
        this.fetchLeasObjectResponseDetails()
        this.needUpdate = false
      }
    }
  },

  created() {
    this.$setLoading(true)
    this.fetchLeasObjectResponseDetails()
  },

  computed: {
    requestId() {
      return this.$route.params.id
    }
  },

  methods: {

    sendLeasObjectResponse(noCar = false) {
      this.noCar = noCar
      if (noCar && this.hasNoCarResp()) {
        this.noCarWarningDialog = true
        return
      }

      if (noCar && !this.hasNoCarResp() && !this.noCarOfferDialog) {
        this.noCarOfferDialog = true
        return
      }
      this.noCarOfferDialog = false

      if (this.leasObjectResps && this.leasObjectResps.length && !this.repeatRespWarningDialog) {
        this.repeatRespWarningDialog = true
        return
      }
      this.repeatRespWarningDialog = false

      if (!this.$refs.leasObjectResponseForm.$refs.form.validate()) return

      const formData = new FormData
      const leasObjectId = this.requestId

      for (const kp of this.$refs.leasObjectResponseForm.kps) {
        if (kp.file) {
          formData.append('files[]', kp.file)
          formData.append('prices[]', kp.price.replace(/\D/g, ''))
          formData.append('comments[]', kp.comment)
        }
      }

      if (!formData.get('files[]') && !this.noCar) {
        this.showErrorSystemNotification('Для отправки коммерческого предложения прикрепите файл')
        return
      }

      // formData.append('comProposal', this.leasObjectResponseForm.form.file)
      formData.append('form', JSON.stringify({ leasObjectId, noCar: this.noCar, ...this.$refs.leasObjectResponseForm.form, noCarOffer: this.noCarOffer }))

      Http.post('/request-leas-objects/create-response', formData)
        .then(response => {
          if (response.data.success) {
            this.$router.push({ name: 'RequestLeasObjectsList', params: { sended: '1' } })
            WS.sendMessage('notification', {
              type: 'new_response',
              noAdmins: true,
              to_user_id: this.leasObjectDetails.managerLcId
            })
          } else {
            this.showErrorSystemNotification(response.data.msg)
          }
        })
    },

    hasNoCarResp() {
      for (const item of this.leasObjectResps) {
        if (item.no_car) return true
      }
      return false
    },

    fetchLeasObjectResponseDetails() {
      Http.get(`/request-leas-objects/details/${this.requestId}`)
        .then(response => {
          if (response.data.error && response.data.msg === 'not-own-request') {
            this.isOwnRequest = false
            return
          }

          response.data.leasObjectDetails.archived = !!response.data.leasObjectDetails.archived
          _.assign(this, response.data)
        })
        .finally(() => {
          this.$setLoading(false)
        })
    },

    cpLink(path) {
      return system.getFileLink(path)
    },

    convertPrice(price) {
      return price ? stringUtils.priceDisplay(price) : '-'
    },

    openModalRezervConfirm(idResp) {
      this.showModalRez = true
      this.typeModal = 3
      this.idOffer = idResp
    },

    openModalRezervCancel(idResp) {
      this.showModalRez = true
      this.typeModal = 4
      this.idOffer = idResp
    },

    submitNoCarOffer(data) {
      this.noCarOffer = data
      this.sendLeasObjectResponse(true)
    }
  }
}
</script>
